import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import useAnalytics from 'hooks/useAnalytics';
import useVwo from 'hooks/useVwo';
import { track } from 'utils/analytics';
import getVwoVariation from 'utils/vwo/getVwoVariation';
import {
  lcCollectionPageType,
  pageContentVariationWrapperType,
  pageSettingsType,
  semLandingPageType,
  defaultPageType,
} from 'types';

const withVwo = (WrappedComponent) => {
  const WithVwo = ({ pageSettings, pageContent, ...props }) => {
    const [pageData, setPageData] = useState(null);
    const vwoClient = useVwo();
    const { query } = useRouter();
    const {
      analytics: { hinAnonymousId },
    } = useAnalytics();

    const { variation: queryVariation } = query;

    const handleExperiment = (experimentData) => {
      // this can be fired before Segment's analytics.js is actually initialized
      setTimeout(() => {
        track('experiment_viewed', {
          ...experimentData,
        });
      }, 1000);
    };

    useEffect(() => {
      // handle no active experiment
      if (pageContent?.campaignKey === 'None') return;

      // eslint-disable-next-line consistent-return
      if (!vwoClient) return;

      const nextSections = getVwoVariation(
        vwoClient,
        pageContent,
        hinAnonymousId,
        handleExperiment,
        queryVariation
      );
      // eslint-disable-next-line consistent-return
      return setPageData(nextSections);
    }, [vwoClient, pageContent, hinAnonymousId, queryVariation]);

    return pageContent?.campaignKey === 'None' ? (
      <WrappedComponent
        pageSettings={pageSettings}
        pageContent={pageContent?.variationsCollection?.items[0]}
        {...props}
      />
    ) : (
      pageData && (
        <WrappedComponent pageSettings={pageSettings} pageContent={pageData} {...props} />
      )
    );
  };

  WithVwo.propTypes = {
    pageSettings: pageSettingsType.isRequired,
    pageContent: PropTypes.oneOfType([
      lcCollectionPageType,
      defaultPageType,
      semLandingPageType,
      pageContentVariationWrapperType,
    ]).isRequired,
  };

  return WithVwo;
};

export default withVwo;
