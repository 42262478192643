import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';

const commonProps = {
  title: PropTypes.string.isRequired,
  content: simpleRichTextType.isRequired,
};

export const faqProps = {
  ...commonProps,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export const faqType = PropTypes.shape({
  ...commonProps,
});
