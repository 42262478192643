import PropTypes from 'prop-types';

import { contentfulImageType } from 'types/common/contentfulImage.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';

const commonProps = {
  heading: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  disclosures: simpleRichTextType,
};

export const compactBannerProps = {
  ...commonProps,
  images: PropTypes.arrayOf(contentfulImageType).isRequired,
};

export const compactBannerDefaultProps = {
  bgColor: 'backgroundLightGray',
  disclosures: null,
};

export const compactBannerType = PropTypes.shape({
  ...commonProps,
  imagesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulImageType),
  }).isRequired,
});
