import PropTypes from 'prop-types';

import { contentfulPaddingType } from 'types/common/contentfulPadding.types';
import { ctaType } from 'humanity/components/cta/cta.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';

export const ctaBannerProps = {
  backgroundColor: PropTypes.string,
  alignment: PropTypes.oneOf(['center', 'left']),
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  cta: ctaType.isRequired,
  valueProps: PropTypes.arrayOf(PropTypes.string),
  disclosures: simpleRichTextType,
};

export const ctaBannerType = PropTypes.shape({
  ...ctaBannerProps,
});
