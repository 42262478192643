import PropTypes from 'prop-types';

import { contentfulImageType } from 'types/common/contentfulImage.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const payrollIntegrationCardProps = {
  title: PropTypes.string.isRequired,
  logo: contentfulImageType,
  url: PropTypes.string,
};

export const payrollIntegrationCardType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...payrollIntegrationCardProps,
});
