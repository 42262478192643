import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { faqType } from 'humanity/components/faq/faq.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

const commonProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string.isRequired,
  additionalCopy: simpleRichTextType.isRequired,
};

export const faqsProps = {
  ...commonProps,
  faqs: PropTypes.arrayOf(faqType).isRequired,
};

export const faqsDefaultProps = {
  backgroundColor: 'white',
  topPadding: 128,
  bottomPadding: 128,
};

export const faqsType = PropTypes.shape({
  ...commonProps,
  faqsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(faqType).isRequired,
  }),
});
