import PropTypes from 'prop-types';

import { contentfulSysType } from 'types/common/contentfulSys.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { ctaType } from 'humanity/components/cta/cta.types';

export const pricingPlanType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  badge: PropTypes.string,
  baseFee: PropTypes.number.isRequired,
  discountedBaseFee: PropTypes.number,
  employeeFee: PropTypes.number.isRequired,
  discountedEmployeeFee: PropTypes.number,
});

export const planFeatureType = PropTypes.shape({
  content: simpleRichTextType.isRequired,
  plansCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
});

// We define PricingCard types here to avoid circular references
const pricingCardCommonProps = {
  plan: pricingPlanType.isRequired,
  badgeColor: PropTypes.string.isRequired,
  extraBadgeText: PropTypes.string,
  baseFeeText: PropTypes.string.isRequired,
  employeeFeeText: PropTypes.string.isRequired,
  showSavings: PropTypes.bool,
  content: simpleRichTextType,
  cta: ctaType,
};

export const pricingCardProps = {
  ...pricingCardCommonProps,
  features: PropTypes.arrayOf(planFeatureType),
  previousPlan: pricingPlanType,
};

export const pricingCardType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...pricingCardCommonProps,
});

const pricingCardsCommonProps = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  savingsToggleText: PropTypes.string,
  savingsToggleDefaultValue: PropTypes.bool,
  banner: simpleRichTextType,
  disclosures: simpleRichTextType,
  featuresHeading: PropTypes.string,
  featuresDisclosures: simpleRichTextType,
};

export const pricingCardsProps = {
  ...pricingCardsCommonProps,
  pricingCards: PropTypes.arrayOf(pricingCardType).isRequired,
  features: PropTypes.arrayOf(planFeatureType),
};

export const pricingCardsDefaultProps = {
  subheading: '',
  disclosures: null,
  featuresHeading: '',
  features: null,
  featuresDisclosures: null,
};

export const pricingCardsType = PropTypes.shape({
  ...pricingCardsCommonProps,
  sys: contentfulSysType.isRequired,
  pricingCardsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(pricingCardType).isRequired,
  }).isRequired,
  featuresCollection: PropTypes.shape({
    items: PropTypes.arrayOf(planFeatureType),
  }),
});
