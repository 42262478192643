import PropTypes from 'prop-types';

import { contentfulImageType } from 'types/common/contentfulImage.types';

export const testimonialCardProps = {
  backgroundColor: PropTypes.string.isRequired,
  image: contentfulImageType,
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
};

export const testimonialCardDefaultProps = {
  backgroundColor: 'white',
  image: null,
};

export const testimonialCardType = PropTypes.shape({
  image: contentfulImageType,
  testimonialQuote: PropTypes.string.isRequired,
  quoteAuthor: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
});
