import PropTypes from 'prop-types';

import { testimonialCardType } from 'humanity/components/testimonialCard/testimonialCard.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

const commonProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

export const testimonialsGridProps = {
  ...commonProps,
  testimonials: PropTypes.arrayOf(testimonialCardType).isRequired,
};

export const testimonialsGridDefaultProps = {
  backgroundColor: 'yellow20',
  topPadding: 128,
  bottomPadding: 128,
  subheading: '',
};

export const testimonialsGridType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  testimonialsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(testimonialCardType).isRequired,
  }).isRequired,
});
