import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const largePricingCardProps = {
  backgroundColor: PropTypes.string.isRequired,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  image: contentfulMediaType.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  planIntroText: PropTypes.string.isRequired,
  baseFee: PropTypes.number.isRequired,
  baseFeeText: PropTypes.string.isRequired,
  perEmployeeFee: PropTypes.number.isRequired,
  employeeFeeText: PropTypes.string.isRequired,
  cta: ctaType.isRequired,
  disclosures: simpleRichTextType,
};

export const largePricingCardDefaultProps = {
  backgroundColor: 'white',
  topPadding: 128,
  bottomPadding: 128,
};

export const largePricingCardType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...largePricingCardProps,
});
