import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { ctaType } from 'humanity/components/cta/cta.types';
import { payrollIntegrationCardType } from 'humanity/components/payrollIntegrationCard/payrollIntegrationCard.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const commonProps = {
  backgroundColor: PropTypes.string.isRequired,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string.isRequired,
  completeIntegrationsList: PropTypes.shape({ sys: contentfulSysType.isRequired }),
  cta: ctaType.isRequired,
  ignoreCtaUrl: PropTypes.bool,
  modalCta: ctaType.isRequired,
};

export const payrollIntegrationsProps = {
  ...commonProps,
  subheading: simpleRichTextType,
  integrationLogos: PropTypes.arrayOf(payrollIntegrationCardType).isRequired,
};

export const payrollIntegrationsType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  payrollSubheading: simpleRichTextType,
  integrationLogosCollection: PropTypes.shape({
    items: PropTypes.arrayOf(payrollIntegrationCardType).isRequired,
  }).isRequired,
});
