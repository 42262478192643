import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

const commonProps = {
  heading: PropTypes.string.isRequired,
  content: simpleRichTextType.isRequired,
};

export const numberedStepProps = {
  index: PropTypes.number.isRequired,
  ...commonProps,
};

export const numberedStepType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
});
