import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const fullWidthMediaProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingAlignment: PropTypes.string,
  media: contentfulMediaType.isRequired,
  videoWidth: PropTypes.number,
  videoHeight: PropTypes.number,
  autoplay: PropTypes.bool,
  showControls: PropTypes.bool,
  loop: PropTypes.bool,
  disclosures: simpleRichTextType,
};

export const fullWidthMediaDefaultProps = {
  topPadding: 128,
  bottomPadding: 128,
  videoWidth: 1200,
  videoHeight: 675,
  autoplay: false,
  showControls: true,
  loop: false,
};

export const fullWidthMediaType = PropTypes.shape({});
