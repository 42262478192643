import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';
import { numberedStepType } from 'humanity/components/numberedStep/numberedStep.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

const commonProps = {
  heading: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  cta: ctaType,
};

export const numberedStepListProps = {
  ...commonProps,
  steps: PropTypes.arrayOf(numberedStepType).isRequired,
};

export const numberedStepListDefaultProps = {
  backgroundColor: 'white',
  topPadding: 128,
  bottomPadding: 128,
};

export const numberedStepListType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  stepsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(numberedStepType).isRequired,
  }),
});
