import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const featuredValuePropProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  image: contentfulMediaType.isRequired,
  imagePosition: PropTypes.oneOf(['left', 'right']).isRequired,
  imageAlignment: PropTypes.oneOf(['center', 'bottom', 'top']).isRequired,
  eyebrow: PropTypes.string,
  heading: PropTypes.string.isRequired,
  paragraph: simpleRichTextType.isRequired,
  disclosures: simpleRichTextType.isRequired,
  cta: ctaType,
};

export const featuredValuePropDefaultProps = {
  backgroundColor: 'white',
  topPadding: 128,
  bottomPadding: 128,
  eyebrow: '',
  cta: null,
};

export const featuredValuePropType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...featuredValuePropProps,
});
