import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const featuredTestimonialProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  quote: PropTypes.string.isRequired,
  image: contentfulMediaType,
  author: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
  cta: ctaType,
  disclosures: simpleRichTextType,
};

export const featuredTestimonialDefaultProps = {
  backgroundColor: 'blue100',
  topPadding: 128,
  bottomPadding: 128,
  heading: null,
  subheading: null,
  cta: null,
  image: null,
  disclosures: null,
};

export const featuredTestimonialType = PropTypes.shape({
  ...featuredTestimonialProps,
});
