import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const embeddedMediaProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingAlignment: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  mediaWidth: PropTypes.number.isRequired,
  mediaHeight: PropTypes.number.isRequired,
  disclosures: simpleRichTextType,
};

export const embeddedMediaDefaultProps = {
  backgroundColor: 'white',
  topPadding: 128,
  bottomPadding: 128,
  heading: '',
  subheading: '',
  headingAlignment: 'center',
  disclosures: null,
};

export const embeddedMediaType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...embeddedMediaProps,
});
